<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="cancel-popup" slot="component">
      <form @submit.prevent="submitReasonForm">
        <!-- Heading Sesction -->
        <div class="cancel-popup_heading">
          {{ $t("It’s sad to see you Go!") }}
        </div>

        <br />

        <div class="cancel-popup_heading_sub-heading">
          {{ $t("To improve the service, Please tell us why you choose to unsubscribe.") }}
        </div>

        <!-- Reasons Dropdown -->

        <br />

        <!-- Error Container -->
        <div class="error-container" v-if="formError">
          <p class="error">{{ $t(formError) }}</p>
        </div>

        <br />

        <div class="cancel-popup_sub-heading">
          {{ $t("Select Reason") }}
        </div>

        <select class="drop-layout-cancel" v-model="reasonObj" @change="selectReason">
          <option value="" selected disabled>{{ $t("Please choose one of these reasons") }}</option>
          <option :value="reasonName" v-for="(reasonName, index) in reasonsList" :key="index">{{
            localDisplayLang === "eng" ? reasonName.en : reasonName.ar
          }}</option>
        </select>

        <br />

        <!-- Other reason Text box -->
        <div>
          <label for="otherReason">{{ $t("Please type reason here...") }}</label>
          <textarea
            :placeholder="`${this.$t('Please enter reason')}`"
            v-model="otherReason"
            name="otherReason"
            id="otherReason"
            cols="30"
            rows="5"
          ></textarea>
        </div>

        <!-- The Submit button -->
        <button type="submit" class="button-primary submit"><span class="btn-txt-color">{{ $t("Submit") }}</span></button>
      </form>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../eventBus";
export default {
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },

  props: {
    closePopup: {
      type: Function,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      reasonObj: null,
      reason: "",
      localDisplayLang: null,

      reasonsList: [],
      isReasonTextBox: false,
      otherReason: "",
      formError: "",
    };
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  watch: {
    // reason(val) {
    //   if (
    //     val === "Other" ||
    //     val === "سبب أخر" ||
    //     val === "I wanted to change my payment method to. Please add it to the message." ||
    //     val === "أرغب في تعديل طريقة دفعي للاشتراك . الرجاء ذكرها في النص"
    //   ) {
    //     this.isReasonTextBox = true;
    //   } else {
    //     this.otherReason = "";
    //     this.isReasonTextBox = false;
    //   }
    // },

    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = "";
        }, 5000);
      }
    },
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.reasonsList = this.appConfig.cancellationReasons;

    console.log("THIS IS THE CANCEL POP UP CONFIG -- ", this.reasonsList);
  },

  mounted() {
    this.reasonObj = "";
  },

  methods: {
    selectReason() {
      this.reason = this.localDisplayLang === "eng" ? this.reasonObj.en : this.reasonObj.ar;
      console.log("this.reason ", this.reasonObj);
    },

    submitReasonForm() {
      if (!this.reason) {
        this.formError = "Please enter reason";
        return;
      }

      if (this.reasonObj.isReasonMandatory && !this.otherReason) {
        this.formError = this.$t("Please enter the comment");
        return;
      }

      if ((this.reason && this.reason.length > 256) || (this.otherReason && this.otherReason.length > 256)) {
        this.formError = "The comment should be less than 256 characters";
        return;
      }

      const reasonPayload = {
        reason: `${this.reason}${this.otherReason ? " : " + this.otherReason : ""}`,
      };

      console.log("THE FINAL PAYLOAD - ", reasonPayload);
      // localStorage.setItem("cancelReasonPayload", reasonPayload);
      eventBus.$emit("submit-cancel-reponse-event", reasonPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.drop-layout-cancel {
  // -webkit-appearance: none;
  // background: url("../../../assets/icons/down_arrow.svg") no-repeat 98%;
  line-height: 1rem;
  color: #efeff4;
  &:focus {
    background-color: #000000;
  }
}

.cancel-popup {
  color: #efeff4;
  font-family: $font-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 2rem;

  &_heading {
    font-weight: bold;
    font-size: 18px;

    &.subtitle {
      font-weight: 500;
      font-size: 16px;
    }
  }
  &_sub-heading {
    font-size: 16px;
  }
}

.error-container {
  border: 1px solid red;
  border-radius: 0.2rem;
  padding: 0.7rem;
  margin-bottom: 10px;
  .error {
    font-family: $font-regular;
    font-size: 0.8rem;
    color: red;
    font-weight: 600;
    text-align: center;
  }
}

.submit {
  margin-top: 1.2rem;
  width: 100%;
}
@media only screen and (max-width: 425px) {
  .drop-layout-cancel {
    font-size: 0.8rem;
  }
}
</style>
