<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="otp_container" slot="component">
      <div v-if="showForm">
        <div class="otp_container_header">
          <p v-if="!enableOtp">{{ enterMobileNumber() }}</p>
          <p v-else>{{ $t("Please enter the OTP Sent to your mobile number") }}</p>
        </div>

        <div class="otp_container_error form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
        </div>

        <form @submit.prevent="enableOtp ? verifyOtp() : paymentInit(planInfo)">
          <div v-if="planInfo.isTpay">
            <label for="category"> Choose Tpay provider</label>
            <select v-model="tpayProvider" @change="changeTpayProvider(tpayProvider)">
              <option disabled value="">{{ $t("choose category") }}</option>

              <option v-for="(provider, index) in tpayProviders" :key="index" :value="provider.providerId">{{
                provider.providerName
              }}</option>
            </select>
          </div>

          <div class="otp_container_mobile_number" v-if="!isMobileno">
            <!-- <input
              type="number"
              id="mobileno"
              v-model="mobilenumber"
              onkeypress="return this.value.length > 9 ? false :true"
            /> -->

            <div id="input-container" class="lookup-form-container-input-container ph-number-wrapper" dir="ltr">
              <!-- <div id="countrycode" class="lookup-form-container-input-container-select-code"> -->
                <input
                  v-if="mobilePrefix"
                  type="text"
                  v-model="mobilePrefix"
                  placeholder="+91"
                  id="mobilePrefixDisplay"
                  class="lookup-form-container-input-container-select-code country-code-block"
                  :class="[localDisplayLang === 'ara' ? 'rightPad' : '']"
                  disabled
                />
              <!-- </div> -->
              <input
                id="mobileno"
                class="lookup-form-container-input-container-inputtext"
                type="tel"
								@beforeinput="specialCharValidationMobile($event)"
                dir="ltr"
                v-model="mobilenumber"
                autocomplete="false"
                @keydown.space.prevent
								maxlength="11"
              />
            </div>
          </div>

          <div class="otp_container_body" v-if="enableOtp">
            <div id="pininputs" class="pin-inputs" dir="ltr">
              <input
                v-for="(item, index) in numberOfPinBlocks"
                :key="index"
                class="pin"
                type="text"
                v-model="userPin[item - 1]"
                @input="grabPin"
                maxlength="1"
              />
            </div>
          </div>

          <div class="otp_container_button">
            <button class="button-primary" type="submit">
              <span v-if="!loader && enableOtp">{{ $t("Verify") }}</span>
              <span v-else-if="!loader && !enableOtp">{{ $t("continue") }}</span>
              <Loading v-else></Loading>
            </button>
          </div>
        </form>

        <div class="otp_container_planText">
          <p v-html="planTermsText(planInfo)"></p>

          <p v-if="planInfo.isEtisalatUAE" class="t-and-c-text">
            {{ $t("For complete T’s &C’s") }}
            <span class="click" @click="redirectTermsAndConditions()">{{ $t("Click here") }}</span>
          </p>
        </div>

        <!-- <div v-if="planInfo.isEtisalatUAE" class="otp_container_planText">
          {{ $t("For complete T’s &C’s") }}
          <span class="click" @click="redirectTermsAndConditions()">{{ $t("Click here") }}</span>
        </div> -->
      </div>
      <div v-else>
        <div class="payment-progress-container" v-if="!transactionstatus">
          <p class="please-wait">{{ $t("please wait") }}</p>
          <p class="payment-progress">{{ $t("your payment is in progress") }}</p>
          <img class="loading-gif" src="@/assets/gif/30.gif" />
        </div>
        <div v-else>
          <p class="payment_response_text">{{ paymentResponse }}</p>
          <div class="success_card" v-if="transactionSuccess">
            <img class="tick" src="@/assets/icons/Check1.svg" />
            <p class="plan-title">{{ planInfo.planname }}</p>
            <p class="plan-interval" dir="ltr" v-if="localDisplayLang === 'ara'">
              {{ $t(planInfo.planinterval) + " " + "/" + " " + planInfo.currency + " " + planInfo.amount / 100 }}
            </p>
            <p class="plan-interval" dir="ltr" v-else>
              {{ planInfo.currency + " " + planInfo.amount / 100 + " " + "/" + " " + $t(planInfo.planinterval) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    paymentInfo: {
      type: Object,
    },
    planInfo: {
      type: Object,
    },
    gatewayInfo: {
      type: Object,
    },
    isMobileno: {
      type: Boolean,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      profilePin: "",
      formError: null,
      loader: false,
      showForm: true,
      paymentResponse: "",
      transactionSuccess: false,
      transactionstatus: null,
      localDisplayLang: null,
      counter: 10,
      mobilenumber: null,
      mobilePrefix: "",
      enableOtp: false,
      paymentInitResponse: null,
      countryList: [],
      tpayProvider: "",
      tpayProviderGatewayId: "",
      tpayProviders: [
        {
          providerName: "Orange",
          providerId: "orange",
        },
        {
          providerName: "Vodafone",
          providerId: "vodafone",
        },
        {
          providerName: "WE",
          providerId: "we",
        },
        {
          providerName: "Etisalat",
          providerId: "etisalat",
        },
      ],

      numberOfPinBlocks: 4,
    };
  },

  computed: {
    ...mapGetters(["getCountry", "appConfig"]),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },

    enableOtp(val) {
      if (val) {
        this.setupPin();
      }
    },
		mobilenumber(val) {
      if (val) {
        while (val.charAt(0) === "0") {
          val = val.substring(1);
        }
        this.mobilenumber = val;
      }
    },
  },

  created() {
    if (this.paymentInfo !== undefined) {
      this.paymentInitResponse = this.paymentInfo;
    } else {
      this.getCountries();
    }

    // if (this.planInfo && this.planInfo.isZainKsa) {
    //   this.numberOfPinBlocks = 6;
    // }else
		 if(this.planInfo.isPalestine || this.planInfo.isZainIraq){
			this.numberOfPinBlocks = 5;
		}

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.enableOtp = this.isMobileno ? true : false;
  },

  mounted() {
    // if (this.planInfo.isTpay || this.planInfo.isZainKsa) {
    //   this.numberOfPinBlocks = 6;
    // }
		if (this.planInfo.isTpay ) {
      this.numberOfPinBlocks = 6;
    }
		else if(this.planInfo.isPalestine || this.planInfo.isZainIraq){
			this.numberOfPinBlocks = 5;
		}

    eventBus.$on("paymentDetail-response", (response) => {
      console.log("payment status response", response);
      this.transactionstatus = response.transactionstatus;
      if (this.transactionstatus === "PENDING") {
        this.paymentResponse = "Payment status is pending";
      } else if (this.transactionstatus === "SUCCESS") {
        this.paymentResponse = this.$t("You have successfully subscribed");
        this.transactionSuccess = true;
        this.setNewSubscription(true);
      } else if (this.transactionstatus === "FAILED") {
        this.paymentResponse = "Your payment is failed";
      }
    });
  },

  methods: {
    ...mapMutations(["setNewSubscription"]),
    ...mapActions(["getCountryList", "paymentInitiation", "OTPPaymentConfirmation"]),

    redirectTermsAndConditions() {
      this.$router.push("/TermsOfUse");
    },

    enterMobileNumber() {
      if (this.localDisplayLang == "ara") {
        return this.appConfig.messagesList.ar.enterMobileNumber;
      } else {
        return this.appConfig.messagesList.en.enterMobileNumber;
      }
    },
		specialCharValidationMobile(e) {
      let code = e.data;
      if (!code) return;
      code = code[code.length - 1];

      if (!this.listOfSpecialMobile(code.charCodeAt())) {
        e.preventDefault();
        return false;
      }
    },
		listOfSpecialMobile(charCode) {
      if (charCode >= 65 && charCode <= 122) {
        return false;
      }
      if (charCode != 69 && charCode != 101) {
        for (let i = 65, k = 97, j = 48; i <= 90; i++, j++, k++) {
          if (charCode == i || charCode == k || (j <= 57 && charCode == j)) {
            return true;
            break;
          }
        }
      }
      return false;
    },

    changeTpayProvider(provider) {
      if (this.planInfo.isTpay && provider === "vodafone") {
        this.tpayProviderGatewayId = "TPAYVODAFONE";
      } else if (this.planInfo.isTpay && provider === "orange") {
        this.tpayProviderGatewayId = "TPAYORANGE";
      } else if (this.planInfo.isTpay && provider === "we") {
        this.tpayProviderGatewayId = "TPAYWE";
      } else if (this.planInfo.isTpay && provider === "etisalat") {
        this.tpayProviderGatewayId = "TPAYETISALAT";
      }

      console.log("THE FINAL TPAY PROVIDER", this.tpayProviderGatewayId);
    },

    planTermsText(plan) {
      console.error("NEW PLAN --- ", plan);

      if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged OMR 0.7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 12 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 30 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. 
<br>
You will be getting a 2 days Free Trial once.
<br>
After Free Trial you will be charged 1.49 USD / week. Price includes 5% VAT,
Charges will be deducted from Ooredoo balance.
<br>
Your subscription will renew automatically until cancelled. 
<br>
To cancel, Send UNSUB NPW to 6105. 
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay
your bill.`;
      } 
      else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 30 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
      else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. 
<br> 
You will be getting a 7 days Free Trial once.
<br>
After Free Trial you will be charged 4.49 USD / month, Price includes 5% VAT,
Charges will be deducted from Ooredoo balance.
<br>
Your subscription will renew automatically until cancelled.
<br>
To cancel, Send UNSUB NPM to 6105.
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay 
your bill.`;
      } 

		else if (plan.currency == "IQD" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. 
<br>
You will be getting a 2 days Free Trial once.
<br>
After Free Trial you will be charged 1800 IQD / week. Price includes 5% VAT,
Charges will be deducted from Zain balance.
<br>
Your subscription will renew automatically until cancelled. 
<br>
To cancel, Send 05 by message to 4089. 
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay
your bill.`;
      }  else if (plan.currency == "IQD" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. 
<br> 
You will be getting a 7 days Free Trial once.
<br>
After Free Trial you will be charged 3600 IQD / month, Price includes 5% VAT,
Charges will be deducted from Zain balance.
<br>
Your subscription will renew automatically until cancelled.
<br>
To cancel, Send 05 by message to 4089.
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay 
your bill.`;
      } 


      
      else if (plan.currency === "QAR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged QAR 7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (plan.currency === "QAR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged QAR 20 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 AED weekly.
<br>
Renewals will be automatic every weekly.
<br>
To cancel the service anytime, just send ‘’C NPW” to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 AED monthly.
<br>
Renewals will be automatic every monthly.
<br>
To cancel the service anytime, just send ‘’C NPM” to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 AED weekly.
<br>
Renewals will be automatic every weekly.
<br>
To cancel the service anytime, just send ‘’C NPW” to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 AED monthly.
<br>
Renewals will be automatic every monthly.
<br>
To cancel the service anytime, just send ‘’C NPM” to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      }
      //
      else if (plan.currency == "SAR" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Once your 7 days free trial ends, you will be charged 20 SAR monthly
              Renewals will be automatic every month
              To cancel the service anytime, just send "UNSUBNM" to 712799
              Free trial is applicable for new customers only`;
      } else if (plan.currency == "SAR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Once your 2 days free trial ends, you will be charged 7 SAR weekly
              Renewals will be automatic every week
              To cancel the service anytime, just send "UNSUBNW" to 712799
              Free trial is applicable for new customers only`;
      }
      //
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 0.7 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 12" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 30 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 
      else if (plan.currency == "OMR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } 			      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة.
<br>
ستحصل على (2 يوم) تجربة مجانية.
<br>
عند إنتهاء التجربة المجانية سيتم خصم 1.49 دولارأسبوعياً 
متضمن 5% ضريبة وسيتم خصم المبلغ من رصيدك في Ooredoo
<br>
سيتم تجديد الإشتراك تلقائياً حتى يتم الغاؤه.
<br>
بإمكانك إلغاء الإشتراك في اي وقت عبر إرسال 
" UNSUB NPW" للرقم 6105.
لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن
والديك أو شخص مخوّل بالدفع.`;
      } 
      
      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 30 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      }

      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة.
<br>
ستحصل على (7 يوم) تجربة مجانية.
<br>
عند إنتهاء التجربة المجانية سيتم خصم 4.49 دولارشهرياً 
متضمن 5% ضريبة و سيتم خصم المبلغ من رصيدك في Ooredoo
<br> 
سيتم تجديد الإشتراك تلقائياً حتى يتم الغاؤه.
<br>
بإمكانك إلغاء الإشتراك في اي وقت عبر إرسال 
" UNSUB NPM" للرقم 6105.
لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن
والديك أو شخص مخوّل بالدفع.`;
      }
      
      else if (plan.currency === "QAR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 7 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (plan.currency === "QAR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 20 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `عند إنتهاء التجربة المجانية ( 2 يوم ), سيتم احتساب رسوم الخدمة ( 7 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل أسبوع بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPW للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `عند إنتهاء التجربة المجانية ( 7 أيام ) سيتم احتساب رسوم الخدمة ( 20 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل شهر بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPM للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `عند إنتهاء التجربة المجانية ( 2 يوم ), سيتم احتساب رسوم الخدمة ( 7 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل أسبوع بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPW للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `عند إنتهاء التجربة المجانية ( 7 أيام ) سيتم احتساب رسوم الخدمة ( 20 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل شهر بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPM للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      }
      //
      else if (plan.currency == "SAR" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `عند إنتهاء الفترة التجريبية ( 2 أيام ) سيتم خصم 7 ريال سعودي اسبوعيا
              سيتم تجديد الإشتراك تلقائيا كل اسبوع
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNW" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      } else if (plan.currency == "SAR" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 20 ريال سعودي شهريا
              سيتم تجديد الإشتراك تلقائيا كل شهر
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNM" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      }
    },

    calculatePrice(item) {
      return item.amount / 100;
    },

    setupPin() {
      setTimeout(() => {
        // this.getId = document.getElementById("pininputs");
        this.pinFunctionality(document.getElementById("pininputs"));
      }, 1000);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = parseInt(pin);
      }
    },

    getCountries() {
      store
        .dispatch("getCountryList")
        .then((response) => {
          this.countryList = response;
          if (this.getCountry) {
            this.fetchCurrentCountryCode();
          }
        })
        .catch((error) => console.log(error));
    },

    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        console.log("THE COUNTRT CODE --- ", element.code, this.getCountry.CountryCode);
        // return element.code === this.getCountry.CountryCode;
        return element.code === this.planInfo.country[0];
      });

      if (index > -1) {
        this.mobilePrefix = this.countryList[index].dial_code ? this.countryList[index].dial_code : "";
      }
    },

    constructInitParams(item) {
      let payload = {
        amount: item.amount,
        currency: item.currency,
        devicetype: "WEB",
        transactionpurpose: "SUBSCRIPTION",
        transactionmode: "CC",
        availabilityid: item.availabilityset[0],
        planid: item.planid,
      };

      console.log("THE PREFIX", this.mobilePrefix);

      if (this.mobilenumber) {
        payload.mobileno = this.mobilePrefix + this.mobilenumber;
      }

      return payload;
    },
    paymentInit(plan) {
      if (plan.isTpay && !this.tpayProviderGatewayId) {
        this.formError = "Please Select a provider";
        return;
      }

      if (this.mobilenumber) {
				console.log("this.gatewayInfo",this.gatewayInfo)
        let payload = {
          gatewayid: plan.isTpay === true ? this.tpayProviderGatewayId : this.gatewayInfo.gatewayid,
          params: this.constructInitParams(plan),
        };

        let currentDomain = window.location.origin;

        if (plan.hasOwnProperty("isSTC") && plan.isSTC) {
          payload.params.return_url = currentDomain + "/profile" + "?" + "planid=" + plan.planid;
        } else if (
          (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
          (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
        ) {
          payload.params.client_return_url = currentDomain + "/profile" + "?" + "planid=" + plan.planid;
        }

        this.loader = true;
        this.paymentInitiation(payload)
          .then((response) => {
            this.loader = false;
            console.log("payment init response", response);
            if (response.data.reason) {
              this.formError = this.$t(response.data.reason);
            } else {
              if (
                (plan.hasOwnProperty("isSTC") && plan.isSTC) ||
                (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
                (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
              ) {
                let redirectURL = "";
                if (response.data.referencedata && response.data.referencedata.paymenturl) {
                  redirectURL =
                    response.data.referencedata.paymenturl + `?&lang=${this.localDisplayLang === "ara" ? "ar" : "en"}`;
                } else if (response.data.referencedata.checkoutUrl) {
                  redirectURL = response.data.referencedata.checkoutUrl;
                }
                window.open(redirectURL, "_self");
              } else {
                this.disableInputField();
                this.paymentInitResponse = response;
                console.log("ENABLE OTP ", this.enableOtp)
                this.enableOtp = true;
              }
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.formError = this.enterMobileNumber();
      }
    },

    verifyOtp() {
      if (this.profilePin) {
        this.paymentInitResponse.data.referencedata.otpverify = this.profilePin;
        this.paymentInitResponse.data.referencedata.gwprovider = this.gatewayInfo.gatewayid;

        if (this.mobilenumber) {
          this.paymentInitResponse.data.referencedata.mobileno = this.mobilePrefix + this.mobilenumber;
        }

        let payload = {
          // gatewayid: this.gatewayInfo.gatewayid,
          gatewayid: this.planInfo.isTpay === true ? this.tpayProviderGatewayId : this.gatewayInfo.gatewayid,

          params: {
            referenceid: this.paymentInitResponse.data.referenceid,
            referencedata: JSON.stringify(this.paymentInitResponse.data.referencedata),
          },
        };

        console.log("payload for verifyOtp", payload);

        this.loader = true;
        this.OTPPaymentConfirmation(payload)
          .then((response) => {
            this.loader = false;
            console.log("paymentConfirmation response", response);
            if (response.data.reason) {
              this.formError = response.data.reason;
            } else {
              this.showForm = false;
              //show timer for customer and then call payment status api.
              this.decrementCounter(response);
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.formError = this.$t("Please Enter OTP");
      }
    },
    //showing loader for 10 seconds so the payment status will be changed to success for razorpay.
    decrementCounter(callbackResponse) {
      if (this.counter > 1) {
        let timer = setInterval(() => {
          this.counter--;
          if (this.counter < 0) {
            clearInterval(timer);
            this.checkPaymentStatus(callbackResponse);
          }
        }, 1000);
      }
    },

    checkPaymentStatus(callbackResponse) {
      let payload = {
        referenceid: callbackResponse.data.referenceid,
      };
      eventBus.$emit("paymentDetails", payload);
    },

    disableInputField() {
      let dom = document.getElementById("mobileno");
      dom.disabled = true;
      dom.style.color = "rgba(255,255,255, 0.3)";
      dom.style.cursor = "not-allowed";

      // Disable the country Code fix
      let prefix = document.getElementById("mobilePrefixDisplay");
      prefix.disabled = true;
      prefix.style.color = "rgba(255,255,255, 0.3)";
      prefix.style.cursor = "not-allowed";
    },
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  mixins: [Utility],

  beforeDestroy() {
    eventBus.$off("paymentDetail-response");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.ph-number-wrapper {
  display: flex ;
}

.country-code-block {
  text-align: center;
  width: 25% !important;
}

.click {
  cursor: pointer;
  color: #aa1a62;
}
.t-and-c-text {
  margin: 10px 0;
}

.otp_container {
  padding: 2rem;
  &_header {
    p {
      color: #efeff4;
      font-family: $font-regular;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  &_error {
    margin: 10px 0;
  }
  &_mobile_number {
    margin-bottom: 10px;
    input[type="number"] {
      width: 100%;
      margin: 0.4rem 0;
      padding: 0.7rem;
      background: transparent;
      border: 1px solid $clr-popup-label;
      border-radius: 0.1rem;
      caret-color: $clr-popup-label;
      color: #efeff4;
      font-family: $font-regular;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 12px;
      &:focus {
        outline: none;
        border-radius: 2px;
        background-color: #000000;
        border: 1px solid $clr-dark-gd3;
        color: #efeff4;
      }
    }
  }
  &_body {
    margin: 10px 0;
    .pin-inputs {
      display: flex;
      justify-content: space-between;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
      .pin {
        text-align: center;
        padding: 0.5rem 0.2rem;
      }
    }
  }
  &_planText {
    margin-top: 10px;
    p {
      color: #b7b7b7;
      font-family: $font-regular;
      font-size: 0.6rem;
      letter-spacing: 0;
      text-align: justify;
      line-height: 0.8rem;
     // white-space: pre-line;
      text-align: center;
    }
  }
  .payment-progress-container {
    text-align: center;
    .please-wait {
      color: #b8c0c7;
      font-family: $font-regular;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 36px;
    }
    .payment-progress {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .loading-gif {
      width: 85px;
    }
  }
  .payment_response_text {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  .success_card {
    border: 1px solid #32363e;
    border-radius: 10px;
    background-color: #191b20;
    position: relative;
    padding: 0.6rem;
    text-align: left;
    margin: 20px 0;
    .tick {
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .plan-title {
      color: #f8fafd;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }
    .plan-type {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .plan-interval {
      color: #4d7bff;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}
</style>
